import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Hero from "../components/Hero/Hero"
import Container from "../components/Container/Container"
import Recents from "../components/Recents/Recents"
import Testimonial from "../components/Testimonials/Testimonials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faHome } from "@fortawesome/free-solid-svg-icons"
import imageLeft from "../images/team-foto.jpg"
import BlogGrid from "../components/Blog/BlogGrid"
import CtaButton from "../components/CtaButton/cta"
import { graphql, useStaticQuery } from "gatsby"

const links = [
  {
    label: "Een woning kopen",
    link: "/dienst/kopen",
  },
  {
    label: "Mijn woning verkopen",
    link: "/dienst/verkopen",
  },
  {
    label: "Mijn woning laten taxeren",
    link: "/dienst/taxatie",
  },
]

const IndexPage = ({ data }) => {
  const seo = useStaticQuery(
    graphql`
      query HomePage {
        wpPage(slug: { eq: "home" }) {
          seo {
            metaDesc
            title
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Seo
        title={seo.wpPage.seo.title}
        description={seo.wpPage.seo.metaDesc}
      ></Seo>
      <Hero
        title="Als het om wonen gaat, is alleen het beste goed genoeg"
        links={links}
        ctaTitle="Ik wil graag.."
        video={"video"}
      ></Hero>
      <Recents></Recents>
      <div className="bg-light my-12 relative">
        <Container>
          <div className="w-full py-16 lg:px-0 px-5">
            <h2 className="text-3xl text-blue w-full lg:w-2/5">
              Bij Groenhout Makelaars bent u aan het juiste adres
            </h2>
            <p className="w-full lg:w-1/2 lg:pb-0 pb-4 lg:pr-4">
              Groenhout Makelaars is een innoverend en vakkundig
              makelaarskantoor actief in heel Drenthe, Groningen en de kop van
              Overijssel met een team van ervaren professionals die altijd gaan
              voor het beste resultaat. Bij ons vindt u een specialist op ieder
              gebied als het gaat om wonen. Of u nu een huis wilt verkopen,
              aankopen of een taxatie nodig bent, met ons team aan specialisten
              bent u op het juiste adres.
            </p>
            <div className="block w-full mx-auto lg:mx-0 lg:w-1/3">
              <CtaButton
                target={"/over-ons"}
                title="Meer over Groenhout"
                icon={faArrowRight}
              ></CtaButton>
            </div>
          </div>
          <img
            src={imageLeft}
            className={
              "object-cover h-96 lg:h-full w-full lg:w-1/2 relative lg:absolute lg:right-0 lg:top-0"
            }
          ></img>
        </Container>
      </div>
      <Testimonial></Testimonial>
      <Container styles="relative w-full max-w-none lg:mt-6 mb-16 lg:px-0 px-5">
        <h2 className="text-blue w-full lg:w-1/3 text-4xl">
          Bekijk de laatste ontwikkelingen
        </h2>
        <p className="text-blue opacity-40 mb-4">
          {" "}
          Lees onze inzichten over de huidige woningmarkt.
        </p>
        <div className="inline-block mb-16">
          <CtaButton
            target={`/blog`}
            title="Bekijk alle blogs"
            icon={faArrowRight}
          ></CtaButton>
        </div>
        <BlogGrid limit={true}></BlogGrid>
      </Container>
    </>
  )
}

export default IndexPage

// export const pageQuery = graphql`
//   query($id: Int!){
//     wpPage(slug: {eq: "home"}) {
//       seo{
//           metaDesc
//           title
//         }
//       featuredImage {
//         node {
//           mediaItemUrl
//         }
//       }
//     }
//   }
// `
